<template>
  <div style="background-color: #fff;">
    <div class="PERInformation-head">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>机构管理</el-breadcrumb-item>
        <el-breadcrumb-item>教付保机构管理详情</el-breadcrumb-item>
        <el-breadcrumb-item>{{ Identity }}修改</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-if="Identity == '教师'">
      <el-form :model="AddTeacher">
        <el-form-item label="证件照" :label-width="formLabelWidth">
          <template>
            <img :src="AddTeacher.photo" style="width: 100px" alt="" />
          </template>
        </el-form-item>

        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input
            v-model="AddTeacher.full_name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="教龄" :label-width="formLabelWidth">
          <el-input
            v-model="AddTeacher.master_age"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="教师类型" :label-width="formLabelWidth">
          <el-input v-model="AddTeacher.identity" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="性别" :label-width="formLabelWidth">
          <el-input v-model="sex" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号" :label-width="formLabelWidth">
          <el-input v-model="AddTeacher.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="专长" :label-width="formLabelWidth">
          <el-select v-model="AddTeacher.specialty" placeholder="请选择专长">
            <el-option
              :label="Institutionstwo.categories_child"
              :value="Institutionstwo.categories_child"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="科目" :label-width="formLabelWidth">
          <el-input
            v-model="AddTeacher.categories_child"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="老师简介" :label-width="formLabelWidth">
          <el-input
            v-model="AddTeacher.introduction_content"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="设置账号" :label-width="formLabelWidth">
          <el-input
            v-model="AddTeacher.login_name"
            autocomplete="off"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="登陆密码" :label-width="formLabelWidth">
          <el-input
            v-model="AddTeacher.login_pass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="AddTeacherChange()">确 定</el-button>
      </div>
    </div>
    <div v-if="Identity == '课程'">
      <el-form :model="AddTeacher" style="width: 50%">
        <el-form-item label="课程封面" :label-width="formLabelWidth">
         <avatar-uploader @getImgUrl="Uploadcourintroductioncover" :clear="true" :url="AddTeacher.face_url"></avatar-uploader>
        </el-form-item>
        <el-form-item label="课程详情图片" :label-width="formLabelWidth">
        <div style="display: flex;">
                <avatar-uploader @getImgUrl="Uploadimg2" :clear="true" :url="piclist[0]"></avatar-uploader>
             <avatar-uploader @getImgUrl="Uploadimg2a" :clear="true" :url="piclist[1]"></avatar-uploader>
             <avatar-uploader @getImgUrl="Uploadimg2b" :clear="true" :url="piclist[2]"></avatar-uploader>
        </div>
        </el-form-item>
        <el-form-item label="课程标题" :label-width="formLabelWidth">
          <el-input v-model="AddTeacher.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="分销利润（0.01）" :label-width="formLabelWidth">
          <el-input
            v-model="AddTeacher.distribution_profit"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程城市" :label-width="formLabelWidth">
         <el-input
            v-model="AddTeacher.city"
            autocomplete="off"
          ></el-input>
          <!-- <el-select v-model="AddTeacher.city">
            <el-option
              v-for="(item, index) in Location.CountryRegion"
              :key="index"
              :label="item.citysName"
              :value="item.citysName"
            ></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item
          :hide-required-asterisk="true"
          label="课程类别"
          :label-width="formLabelWidth"
        >
          <el-select
            v-model="AddTeacher.categories"
            placeholder="请选择课程类别"
          >
            <el-option
              v-for="(item, index) in ListPageChild_child"
              :key="index"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课程类型" :label-width="formLabelWidth">
          <el-radio-group v-model="AddTeacher.commodity_type">
            <el-radio :label="1">优学A卡</el-radio>
            <el-radio :label="2">优学B卡</el-radio>
            <el-radio :label="3">体验卡</el-radio>
            <el-radio :label="4">标准课</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="课时数" :label-width="formLabelWidth">
          <el-input
            v-model="AddTeacher.course_num"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="套餐价格" :label-width="formLabelWidth">
          <el-input v-model="AddTeacher.amout" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="活动价格" :label-width="formLabelWidth">
          <el-input
            v-model="AddTeacher.discount_amout"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程简介" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            v-model="AddTeacher.introduction_content"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-input v-model="AddTeacher.status" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="AddTeacherChange">确 定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import Location from "@/js/city.js";
export default {
  name: "PERInformation",
  data() {
    return {
      Location,
      ListPageChild: [],
      ListPageChild_child: [],
      recording: [], //课程数据
      AddTeacher: {
        //教师修改
        login_pass: "",
        login_name: "",
        categories_child: "",
        nick_name: "",
        avatar: "",
        mobile: "",
        sex: "",
        specialty: "",
        introduction_content: "",
        master_age: "",
        identity: "",
        //课程修改
        title: "",
        categories: "",
        type: "",
        appointment_type: "",
        course_num: "",
        status: "",
      },
      sex: "",
      formLabelWidth: "140px",
      // 图片上传
      piclist: [],
      activeImg: -1,
      dialogImageUrl: "",
      domain: "http://up-z0.qiniup.com",
      upload_qiniu_addr: "http://img.curiousmore.com/",
      QiNiYunL: {
        key: "",
        token: "",
      },
      id: {},
      Identity: this.$route.query.Identity,
      Institutions: this.$route.query.Institutions,
      Institutionstwo: JSON.parse(this.$route.query.Institutions),
    };
  },
  mounted() {
    this.id = JSON.parse(this.$route.query.id);
    this.id.introduction_url != ""
      ? (this.piclist = this.id.introduction_url.split(","))
      : (this.piclist = []);
    this.GetListPageChild();
    this.GetListPageChild_child();
    this.gettoken(); // 请求token
    if (this.Identity == "教师") {
      this.AddTeacher = this.id;
      if (this.AddTeacher.sex == 1) {
        this.sex = "男";
      } else {
        this.sex = "女";
      }
    } else if (this.Identity == "课程") {
      this.AddTeacher = this.id;
    }
  },
  methods: {
    Uploadimg2(file) {
        this.piclist[0]=file
    },
     Uploadimg2a(file) {
        this.piclist[1]=file
    },
     Uploadimg2b(file) {
        this.piclist[2]=file
    },
    SetupImg(index) {
      this.activeImg = index;
    },
    deleteimg(index) {
      this.piclist.splice(index, 1);
    },

    AddTeacherChange() {
      if (this.Identity == "教师") {
        if (this.sex == "男") {
          this.AddTeacher.sex = 1;
        } else {
          this.AddTeacher.sex = 2;
        }
        let url =
          
          "/user/masterInfo/updateMechanismMasterInfo";
        let data = {
          login_pass: this.AddTeacher.login_pass,
          login_name: this.AddTeacher.login_name,
          categories_child: this.AddTeacher.categories_child,
          full_name: this.AddTeacher.full_name,
          avatar: this.AddTeacher.photo,
          mobile: this.AddTeacher.mobile,
          sex: this.AddTeacher.sex,
          specialty: this.AddTeacher.specialty,
          introduction_content: this.AddTeacher.introduction_content,
          master_age: this.AddTeacher.master_age,
          mechanism_id: this.id.map.mechanismEntity.id,
          id: this.id.id,
          identity: this.AddTeacher.identity,
        };

        this.$axios.post(url, data).then((res) => {
          alert("教师修改成功");
          this.$router.push({
            name: "TeachFuBaodetails",
            query: { id: this.Institutions },
          });
        });
      } else if (this.Identity == "课程") {
        let url =  "/user/masterSetPrice/update";
        let data = {
          amout: this.AddTeacher.amout,
          city: this.AddTeacher.city,
          default_discount_price: this.AddTeacher.discount_amout,
          discount_amout: this.AddTeacher.discount_amout,
          introduction_url: this.piclist.toString(),
          face_url: this.AddTeacher.face_url,
          introduction_content: this.AddTeacher.introduction_content,
          title: this.AddTeacher.title,
          categories: this.AddTeacher.categories,
          type: "mechanism_offline",
          appointment_type: this.AddTeacher.appointment_type,
          course_num: this.AddTeacher.course_num,
          status: this.AddTeacher.status,
          distribution_profit: this.AddTeacher.distribution_profit,
          id: this.id.id,
          commodity_type: this.AddTeacher.commodity_type,
        };
        if (this.AddTeacher.commodity_type == 1) {
          data.commodity_type = "A卡";
        } else if (this.AddTeacher.commodity_type == 2) {
          data.commodity_type = "B卡";
        } else if (this.AddTeacher.commodity_type == 3) {
          data.commodity_type = "体验卡";
        } else if (this.AddTeacher.commodity_type == 4) {
          data.commodity_type = "标准课";
        }
        this.$axios.post(url, data).then((res) => {
          // alert("课程修改");
          window.location.href = "javascript:history.go(-1)";
        });
      }
    },
    gettoken() {
      let _this = this;
      var timestamp = new Date().getTime();
      let data = {
        key: timestamp,
        type: "pic",
      };
      _this
        .$axios({
          method: "post",
          url: "http://101.37.66.151:8768/eg-api/push/upload/getToken",
          data: data,
        })
        .then((res) => {
          _this.QiNiYunL.token = res.data.data;
          _this.QiNiYunL.key = data.key;
        })
        .catch((err) => {});
    },
    GetListPageChild() {
      let url =
        
        "/user/mechanismCategory/queryListPageChild?type=1&status=2&source=商户";
      this.$axios.get(url).then((res) => {
        this.ListPageChild = res.data.data;
      });
    },
    GetListPageChild_child() {
      let url =
        
        "/user/mechanismCategory/queryListPageChild?type=2&status=2&source=商户";
      this.$axios.get(url).then((res) => {
        this.ListPageChild_child = res.data.data;
      });
    },
    Uploadcourintroductioncover(file) {
      this.AddTeacher.face_url = this.upload_qiniu_addr + file.key;
    },
  },
};
</script>
<style  scoped lang="less">
@import "../../assets/css/noTutop.less";
/* 图片上传 */
.my-upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  display: block;
}

.my-upload > .el-icon-plus:before {
  display: none;
}

.avatar-uploader1 {
  display: inline;
  width: 100px;
  height: 100px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
</style>